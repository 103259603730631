<template>
  <img
    :src="`/assets/blob-left.png`"
    alt="blobleft"
    class="absolute md:w-96 pointer-events-none opacity-30"
    :class="positionClass !== '' ? positionClass : 'md:top-0 md:left-0'"
  />
</template>
<script>
export default {
  name: "BlobLeft",
  props: {
    positionClass: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
