<template>
  <div id="services" class="relative h-auto overflow-x-hidden">
    <BlobLeft />
    <BlobRight />
    <p class="text-primary text-center font-[1100] text-3xl pt-10">
      {{ $t("ourServicesTitle") }}
    </p>
    <div class="py-4 md:py-12 px-6 mx-auto max-w-6xl">
      <div class="flex flex-col md:flex-row gap-5">
        <div
          v-for="service in services"
          class="flex flex-col md:w-1/3 w-full h-auto max-h-full relative pt-12 md:pt-24 pb-24 px-8 space-y-4 bg-primary z-10 rounded-lg duration-500 ease-in-out cursor-pointer hover:shadow-2xl"
        >
          <div class="flex flex-wrap items-center lg:space-x-3">
            <div class="mx-auto">
              <img :src="service.icon" :alt="service.title" class="w-32" />
            </div>
            <div class="w-full text-center md:pt-8 pt-4 lg:px-2">
              <h2 class="lg:text-2xl lg:font-black text-base font-extrabold">
                {{ $t(`${service.titleKey}`) }}
              </h2>
            </div>
          </div>
          <div class="w-full text-center pt-2 md:pt-10">
            <p class="lg:text-base text-xs leading-relaxed">
              {{ $t(`${service.descriptionKey}`) }}
            </p>
          </div>
          <div class="w-full absolute bottom-10 left-[30%]">
            <button
              type="button"
              class="bg-white text-primary py-1 px-4 rounded-lg font-extrabold hover:text-white hover:bg-bluebutton duration-500"
            >
              {{ $t("viewService") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BlobLeft from "./BlobLeft.vue";
import BlobRight from "./BlobRight.vue";
export default {
  name: "ServicesComponent",
  components: {
    BlobLeft,
    BlobRight,
  },
  data: () => ({
    services: [
      {
        icon: "/assets/service/acquire.png",
        titleKey: "titleService1",
        descriptionKey: "descriptionService1",
        link: "#",
      },
      {
        icon: "/assets/service/consulting.png",
        titleKey: "titleService2",
        descriptionKey: "descriptionService2",
        link: "#",
      },
      {
        icon: "/assets/service/adapt.png",
        titleKey: "titleService3",
        descriptionKey: "descriptionService3",
        link: "#",
      },
    ],
  }),
};
</script>
