export default{
    halo: "Hola",
    english: "Inglés",
    spanish: "Español",
    // Jumbotron
    home: "Inicio",
    services: "Servicios",
    aboutUs: "Sobre Nosotros",
    blog: "Blog",
    contactUs: "Contáctanos",
    jumbotronText: "Resuelve y gestiona en línea cualquier tipo de conflicto",
    jumbotronSubText:
      "Implementamos todo tipo de plataformas tecnológicas para conciliación, arbitraje y solución de conflictos",
    signIn: 'Iniciar sesión',
    signUp: 'Regístrate',
    // Services
    ourServicesTitle: "Nuestros Servicios",
    titleService1: "Adquiere Tecnologías Especializadas",
    descriptionService1: "Aportamos la mejor tecnología ODR para que tú y tu empresa puedan adecuarlas a las necesidades que en ese momento estén requiriendo",
    titleService2: "Consultoría para el desarrollo",
    descriptionService2: "Trabajamos en conjunto con tu equipo tecnológico para crear y desarrollar las mejores soluciones electrónicas de controversias",
    titleService3: "Adaptamos cualquier plataforma a tus necesidades ",
    descriptionService3: "Ponemos a tu disposición un equipo especializado de desarrolladores para que trabajen en las tecnologías ya establecidas y las adapten a todos los requerimientos que tenga. Para mayor información sobre nuestro equipo, por favor visite sus perfiles",
    viewService: "Ver Servicio",
    // Who Can Access
    whoCanAccessTitle: '¿Quién puede acceder a nuestros servicios?',
    titleWho1: "Instituciones Públicas",
    descriptionWho1: "Todas aquellas instituciones del sector público que deseen optimizar, a través de plataformas, sus procedimientos de carácter administrativo y judicial que impliquen la solución de alguna controversia",
    titleWho2: "Empresas privadas",
    descriptionWho2: "Aquellas que requieran gestionar y realizar un seguimiento directo y exhaustivo a cada uno de los conflictos que se puedan generar con sus clientes y que quieran implementar y optimizar sus sistemas de servicio al cliente y PQR. Actualmente, centramos nuestros desarrollos en plataformas para clientes en sectores financieros y de servicio al cliente, quienes regularmente gestionan gran cantidad de disputas diarias con gran riesgo de ser tramitadas ante la justicia ordinaria.",
    titleWho3: "Centro de arbitraje y conciliación",
    descriptionWho3:"Centros de arbitraje con un funcionamiento ya establecido que deseen optimizar la manera por la cual adelantan sus procedimientos con el uso de las tecnologías de la información.",
    // Our success story
    successStoryTitle: "Conoce los casos de éxito",
    successStorySubTitle: "Procuraduría General de la Nación",
    successStorySubTitleSub: "",
    successStoryDescription1: "Junto con CREK, la plataforma mundial de solución de controversias en línea, se logró implementar el piloto de conciliación virtual para el Centro de Conciliación en materia civil y comercial de la Procuraduría General de la Nación. Por medio de este piloto, se puso en marcha una plataforma para que, las partes, sus apoderados y los conciliadores, lograran llevar a cabo las conciliaciones enteramente en línea.",
    successStoryDescription2: "Lideramos la implementación de la regulación de plataformas de Resolución de Controversias en Línea incluida la contemplada en la Circular 18 No. 0000082-DJU-1500 del Ministerio de Justicia y del Derecho, la cual adoptó Reglas Modelo para el Arbitraje en Línea en el contexto de transacciones con garantías mobiliarias. Junto con nuestros proveedores, hemos implementado procesos electrónicos de negociación, conciliación y arbitraje tanto en instituciones públicas, como privadas.",
    viewAllCases: "Ver todos los casos",
    // Why Use
    whyUseTitle: "¿POR QUÉ UTILIZAR UNA PLATAFORMA EN LÍNEA",
    whyUseSubTitle: "PARA RESOLVER SUS CONTROVERSIAS?",
    whyUseTitle1: "Más eficaz",
    whyUseDescription1: "Las plataformas de resolución de controversias en línea tienen una tasa de éxito significativamente mayor que las herramientas internas de resolución de controversias convencionales",
    whyUseTitle2: "Aumenta la eficiencia",
    whyUseDescription2: "La resolución de controversias en línea reduce a más de la mitad el tiempo necesario para resolverlas; además, se puede ejecutar de forma remota, lo que reduce aún más los costos",
    whyUseTitle3: "Transparencia mejorada",
    whyUseDescription3: "La plataforma de resolución de controversias en línea permite a un tercero neutral y especializado regular los intereses específicos de las partes",
    whyUseTitle4: "Costos reducidos",
    whyUseDescription4: "Una plataforma de resolución de controversias en línea agiliza el proceso de resolución y reduce los casos en los que se requiere un arbitraje formal. Además, los honorarios legales externos se eliminan por completo",
    whyUseTitle5: "Menos litigios",
    whyUseDescription5: "Resulta en una reducción (a la mitad) de acciones legales contra varias instituciones",
    whyUseTitle6: "Capacidad flexible",
    whyUseDescription6: "La plataforma puede responder a un aumento en el volumen de controversias que puede ocurrir estacionalmente",
    whyUseTitle7: "Escalable",
    whyUseDescription7: "La plataforma incluye capacidad para procesos de arbitraje, conciliaciones, mediaciones, negociaciones y en general cualquier proceso que tenga más de dos partes contendientes",
    whyUseTitle8: "Iinteroperabilidad",
    whyUseDescription8: "Esta plataforma se puede integrar fácilmente con el modelo operativo existente del cliente",
    // About Redek
    aboutRedek: "Sobre REDEK",
    aboutParagraph1: "REDEK es un emprendimiento colombiano que ofrece un amplio rango de servicios de resolución de controversias completamente en línea.",
    aboutParagraph2: "En REDEK entendemos que, si bien no es posible eliminar completamente el surgimiento de controversias en cualquier sector, sí es posible y, además, viable enfocarnos en asegurarle a nuestros clientes la mejor experiencia posible para su solución.",
    aboutParagraph3: "Todos los años, tanto en el sector público, como en el sector privado, deben solucionarse millones de controversias. Sin embargo, la aproximación que frecuentemente se hace a la solución de controversias desde los mecanismos tradicionales proviene de equipos con un alto nivel de rotación y con conocimiento limitado de las controversias y usando herramientas que no sirven para el propósito. El resultado es un incremento en los costos de la resolución de controversias, un incremento en los procesos retrasados en los tribunales nacionales, daño reputacional y una falta de satisfacción por parte de los clientes o usuarios del sistema judicial.",
    aboutParagraph4: "En este contexto, REDEK le brinda a sus clientes un servicio comprehensivo de Resolución de Controversias en Línea que reduce los costos del trámite de resolución de la controversia e incrementa significativamente la tasa de éxito de su resolución sin formalidades innecesarias.",
    aboutTitle: "Red De Soluciones",
    aboutSubTitle: "Ayudamos a crear y optimizar cualquier plataforma para la resolución de disputas online",
    // Key Scope
    keyScopeTitle: "ALCANCE CLAVE",
    keyScopeSubTitle: "En función de sus necesidades comerciales específicas, REDEK le proporciona una plataforma personalizada de resolución de controversias en línea capaz de ofrecer un amplio servicio que incluye:",
    keyScopeTitle1: "Autoría",
    keyScopeDescription1: "Brindar una plataforma personalizada para la resolución de controversias que ponga a su disposición un abanico de diferentes opciones que le permitirán al cliente elegir qué tipo de servicio de resolución de controversias desea contratar, por cuánto tiempo y bajo qué medios requieren las etapas del trámite ejecutado",
    keyScopeTitle2: "Gestión de casos",
    keyScopeDescription2: "El cliente podrá proporcionar toda la información relevante del caso mediante un envío a la plataforma REDEK. Luego, nuestra plataforma asigna un agente de caso para que dirija la resolución de la controversia de manera oportuna",
    keyScopeTitle3: "Reuniones virtuales",
    keyScopeDescription3: "La plataforma permite al usuario acceder a las salas virtuales a través de las cuales se intercambiará información con el mediador o quien decida sobre las controversias, así como con las demás partes",
    keyScopeTitle4: "Chat en tiempo real",
    keyScopeDescription4: "La plataforma de resolución de controversias tiene un canal de texto, a través del cual los usuarios podrán intercambiar mensajes directamente desde su tablero y enviar archivos adjuntos como fotos, documentos, etc., durante todo el proceso",
    keyScopeTitle5: "Almacenamiento de documentos",
    keyScopeDescription5: "La plataforma contiene un sistema de gestión para los documentos de respaldo utilizados durante todo el proceso",
    keyScopeTitle6: "Nombramientos de terceros",
    keyScopeDescription6: "La plataforma cuenta con un sistema para convocar a terceros; este sistema emite una notificación que llegará a los mediadores o negociadores registrados",
    keyScopeTitle7: "Cronología",
    keyScopeDescription7: "La plataforma resolverá las controversias dentro de un plazo acordado según el nivel de servicio ",
    // At Redek
    atRedekTitle: "En REDEK podrás encontrar múltiples soluciones a tus conflictos",
    atRedekTitle1: "Alta gama de servicios",
    atRedekDescription1: "Te ayudamos a resolver tus problemas sea cual sea su origen y magnitud, tenemos para ti un amplio catálogo de servicios los cuales están dirigidos y ejecutados por los mejores profesionales en el sector, y mejor aún con especialistas en las diversas áreas del derecho",
    atRedekTitle2: "Profesionales Experimentados",
    atRedekDescription2: "En Redek podrás encontrar un catálogo de profesionales con una amplia experiencia en todas las áreas del derecho, cada uno de ellos con un enfoque óptimo que te ayudará a tomar la mejor decisión para adquirir o modificar cualquier tipo de sistema ODR",
    atRedekTitle3: "Servicio al cliente y PQR",
    atRedekDescription3: "En Redek hemos desarrollado una sección especial hecha para resolver de manera óptima todos aquellos inconvenientes que puedan llegar a presentar los clientes con las empresas, para solucionar sus dudas y recibir toda clase de PQR, guardando sus peticiones y ofreciendo la facilidad de un seguimiento por parte de ambos participantes. Todo esto desde los sistemas los cuales te ayudamos a desarrollar e implementar",
    // Profile
    nicolasDescription: "Nicolás es el CEO de REDEK y abogado de la Universidad Externado de Colombia con maestrías en Derecho Internacional y Comparado de la Universidad Nacional de Singapur (NUS), y en Derecho y la Economía Global de la Universidad de Nueva York (NYU). Nicolás fue delegado por Colombia al Grupo III de la Comisión de las Naciones Unidas para el Derecho Mercantil Internacional (CNUDMI) sobre solución de controversias en línea.<br><br>Nicolás ha dedicado parte importante de su carrera a promover el uso de la tecnología en la justicia, tema en torno al cual ha escrito artículos y dictado numerosas conferencias, incluida una TED Talk.<br><br>Su conocimiento y experiencia se ven materializados actualmente en todos los servicios que presta REDEK a sus clientes, en donde siempre se busca responder coherentemente a las necesidades de nuestros clientes.",
    carlosDescription: "Carlos Mario es ingeniero Industrial con Especialización en Alta Gerencia y Maestría en Dirección Comercial y Marketing de la Universidad Complutense de Madrid.<br><br>Cuenta con experiencia de más de veinte años, en cargos directivos en empresas de índole comercial e industrial y en diferentes emprendimientos de software y hardware, entre estas se encuentra Datamind, Grupo Éxito y Terpel.",
    cesarDescription: "Ingeniero Civil de la Universidad Industrial de Santander y Magister en Gestión de Proyectos de la Universidad de Viña del Mar de Chile.<br><br>Cuenta con una amplia experiencia en dirección y gestión operativa y administrativa de proyectos a gran escala en el campo de la ingeniería civil, enfocados principalmente en el sector de hidrocarburos tanto “On Shore” como “Off Shore” de empresas de interventoría y consultoría para Ecopetrol S.A. Así mismo en el ejercicio como funcionario en Pacific Rubiales Energy (hoy Frontera Energy) y Sociedad Portuaria de Puerto Bahía (Cartagena).",
    zakiDescription: "Zaki es un joven emprendedor, como director general de una empresa digital emergente en Indonesia, director general de una empresa internacional en el Reino Unido y Singapur, y dirige varias empresas en Indonesia. Con experiencia en la gestión de empresas de nueva creación, principalmente en proyectos de TI.",
    jamesDescription: "James lidera la definición de estrategia en niveles directivos y la ejecución y entrega de proyectos.<br><br>Tiene más de 10 años de experiencia en consultoría (incluidas Accenture y Deloitte) en industrias de servicios financieros, telecomunicaciones y tecnología.<br><br>Es graduado del programa de Comercio (con mención honorífica) de la University College de Dublín y posee amplias calificaciones profesionales en contabilidad administrativa, ejecución de programas y operaciones.",
    // Footer
    footerTitle: "Infórmate sobre las últimas tendencias en tecnología ODR",
    footerDescription:
      "Accede a nuestro blog para estar al tanto en toda la información que necesita tu empresa.",
    footerFormTitle:
      "Resuelve tus dudas y cotiza tu sistema de ODR",
    footerFormName: "Tu nombre",
    footerFormEmail: "Tu correo electrónico",
    footerFormMessage: "Tu mensaje",
    footerFormPrivacyPolicy: "He leído y acepto la política de privacidad",
    footerFormContactUs: "Contáctanos",
    accessBlog: "Accede al blog",
    ourTeam: "Nuestro Equipo",
    successfullCases: "Casos de éxito",
    footerContact: "Contacto",
}