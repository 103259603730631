export default{
    halo: "Hello",
    english: "English",
    spanish: "Spanish",
    // Jumbotron
    home: "Home",
    services: "Services",
    aboutUs: "About Us",
    blog: "Blog",
    contactUs: "Contact Us",
    jumbotronText: "Solve and manage any type of dispute online",
    jumbotronSubText:
      "We implement all kinds of technological platforms for conciliation, arbitration and dispute resolution",
    signIn: 'Login',
    signUp: 'Sign Up',
    // Services
    ourServicesTitle: "Our Services",
    titleService1: "Acquire Specialized Technologies",
    descriptionService1: "We provide the best ODR technology so that you and your company can adapt them to the needs that you require at that time.",
    titleService2: "Development consulting",
    descriptionService2: "We work together with your tech team to create and develop the best electronic dispute resolution mechanisms.",
    titleService3: "We adapt any platform to your needs",
    descriptionService3: "We put at your disposal a specialized team of developers to work on the established technologies and adapt them to all your requirements. For more information on our team, please visit their profiles",
    viewService: "View Service",
    // Who Can Access
    whoCanAccessTitle: 'Who can access our services?',
    titleWho1: "Public Entities",
    descriptionWho1:
      "All those public entities wishing to optimize their administrative and judicial procedures through platforms that include dispute resolution.",
    titleWho2: "Private companies",
    descriptionWho2:
      "Those needing to manage and carry out a direct and comprehensive follow-up of each dispute that may arise with their clients. And those who wanti to implement and optimize their customer service and questions, complaints, and claims systems. Currently, we focus our developments on platforms for clients in the financial and customer service sectors, who regularly handle many daily disputes with considerable risk of being processed to courts.",
    titleWho3: "Arbitration and Conciliation Center",
    descriptionWho3:
      "Arbitration centers with on established operation wishing to optimize the way in which they carry out their procedures with the use of information technologies.",
    // Our success story
    successStoryTitle: "Get to know our success stories",
    successStorySubTitle: "Procuraduría General de la Nación",
    successStorySubTitleSub: "(Inspector General's Office)",
    successStoryDescription1: "Together with CREK, the global online dispute resolution platform, we implemented the virtual conciliation pilot for the Civil and Commercial Conciliation Center at the Procuraduría de la Nación. Though this pilot, a platform was launched for the parties, their attorneys, and conciliators to carry out conciliation processdings entirely online.",
    successStoryDescription2: "We are leaders in the regulation of ODR platforms including the one found in Order 18 No. 0000082-DJU-1500 by the Ministry of Justice and Law which was adopted for Model Arbitration Rules in the context of the system of security and warranty transactions. Along with our suppliers, we have implemented electronic negotiation, conciliation, and arbitration proceedings both in public and private institutions.",
    viewAllCases: "View All Cases",
    // Why Use
    whyUseTitle: "WHY USE AN ODR PLATFORM TO",
    whyUseSubTitle: "RESOLVE YOUR DISPUTES?",
    whyUseTitle1: "More Effective",
    whyUseDescription1: "Online Dispute Resolution platforms have a significantly higher success rate than in-house conventional dispute resolution tools",
    whyUseTitle2: "Increase Efficiency",
    whyUseDescription2: "ODR more than halves the time required to resolve disputes, in addition, it can be executed remotely, further reducing costs",
    whyUseTitle3: "Improved Transparency",
    whyUseDescription3: "The ODR platforms allows a neutral and specialized third party to regulate the specific desires of the disputants",
    whyUseTitle4: "Reduced Costs",
    whyUseDescription4: "An ODR platform streamlines the dispute process and reduces the instances where formal arbitration is required, in addition, external legal fees are removed completely",
    whyUseTitle5: "Reduce litigation",
    whyUseDescription5: "Results in lower (half) of legal actions against institutions",
    whyUseTitle6: "Flexible Capacity",
    whyUseDescription6: "The platform can respond to an increase in dispute volumes that may occur seasonally",
    whyUseTitle7: "Scalable",
    whyUseDescription7: "The platform includes capability for arbitration proceedings, conciliations, mediations, negotiations, and in general any proceeding that has more than two contrending parties.",
    whyUseTitle8: "Interoperability",
    whyUseDescription8: "This platform can be easily integrated with a client's existing operating model",
    // About Redek
    aboutRedek: "About Redek",
    aboutParagraph1: "REDEK is a Columbian startup that offers a wide range of online dispute resolution services.",
    aboutParagraph2: "At REDEK we understand that, although it is not possible to completely eliminate the emergence of controversies in any sector, it is possible and also feasible to focus on ensuring our clients the best possible experience for their solution.",
    aboutParagraph3: "Every year, in both the public and private sectors, millions of disputes must be resolved. However, the approach that is frequently made to the resolution of disputes from traditional mechanisms comes from teams with a high level of turnover, with limited knowledge of disputes and using tools not fit for the purpose. The result is an increase in the costs of dispute resolution, an increase in delayed processes in national courts, reputational damage and a lack of satisfication from people resorting to the judicial system.",
    aboutParagraph4: "In this context, REDEK provides its clients a comprehensive Online Dispute Resolution service that reduces the costs of the dispute resolution process and significantly increases the success rate of dispute resolution without unnecessary formalities.",
    aboutTitle: "Solution Networks",
    aboutSubTitle: "We help create and optimize any platform for online dispute resolution",
    // Key Scope
    keyScopeTitle: "Key Scope",
    keyScopeSubTitle: "Based on your specific business needs, Redek provides you with a customized Online Dispute Resolution platform capable of a wide service variety which includes:",
    keyScopeTitle1: "Authoring",
    keyScopeDescription1: "Providing a customized platform for dispute resolution that enables a range of different options, allowing the client to choose which type of dispute resolution service they wish to contract, for how long, and under what means they require the stages of the proceeding to be executed",
    keyScopeTitle2: "Case Management",
    keyScopeDescription2: "The client will be able to provide all relevant case information through a submission to the Redek platform. Our platform then assigns a case to an agent to lead the resolution of the dispute in a timely manner",
    keyScopeTitle3: "Virtual Meetings",
    keyScopeDescription3: "Virtual Meetings The platform enables the user to access the virtual rooms through which information can be exchanged with the mediator or conflict solver, as well as the other parties",
    keyScopeTitle4: "Real-time Chat",
    keyScopeDescription4: "The dispute resolution platform has a text channel, through which users will be able to exchange messages directly from their dashboard and to send attachments such as photos, documents, etc. throughout the proceeding",
    keyScopeTitle5: "Document Storage",
    keyScopeDescription5: "The platform contains a management system for the supporting documents used throughout the proceeding",
    keyScopeTitle6: "Third Party Appointments",
    keyScopeDescription6: "The platform has a system for summoning third parties; this system issues a notification that will reachmediators or negotiators registered",
    keyScopeTitle7: "Timeline",
    keyScopeDescription7: "The platform will resolve disputes within an agreed time period according to the  level of service agreed ",
    // At Redek
    atRedekTitle: "At REDEK you will find multiple solutions to your disputes",
    atRedekTitle1: "High range of services",
    atRedekDescription1: "We help you solve your disputes regardless of their origin and magnitude. We have a wide services catalog, which are directed and executed by the best professionals in the sector for you. Even better, there are specialists in various fields of law",
    atRedekTitle2: "Experienced professionals",
    atRedekDescription2: "At Redek, you will find a catalog of professionals with extensive experience in all fields of law, each with an optiomal approach that will help you make the best decision to acquire or modify any type of ODR system",
    atRedekTitle3: "Customer service and petitions, claims and complaints systems",
    atRedekDescription3: "At Redek we have developed a special section made to optimally solve all those inconveniences that customers may have with companies. This is meant to answer their questions, receive all kinds of petitions, claims and complaints, store their requests and offer the ease of a follow-up by both participants. All this from the systems we will help you develop and implement",
    // Profile
    nicolasDescription: "Nicolás is the CEO at REDEK and a lawyer from Universidad Externado de Colombia with master's degrees in International and Comparative Law from National University of Singapore (NUS), and in Law and Global Economy from New York University (NYU). Nicolás was delegated by Colombia to Group III of the United Nations Commission for International Trade Law (UNCITRAL) on Online Dispute Resolution.<br><br>He has dedicated much of his career to promoting the use of technology in justice, a topic on which he has written articles and given numerous lectures, including a TED Talk.<br><br>His knowledge and experience are currently materialized in all the services REDEK provides for its clients, seeking to respond coherently to our client`s needs.",
    carlosDescription: "Carlos Mario is an industrial engineer with a graduate degree specializing in Senior Management as well as a master's degree in Commercial Management and Marketing from Universidad Complutense de Madrid.<br><br>He has more than twenty years of experience in managerial positions in commercial and industrial companies, and in different software and hardware ventures, including Datamind, Grupo Éxito and Terpel.",
    cesarDescription: "César is a civil engineer from Universidad Industrial de Santander and holds a master's degree in Project Management from Universidad de Viña del Mar in Chile.<br><br>He has extensive experience in operative and administrative management and direction of large-scale projects in the field of civil engineering. He has focused mainly on the hydrocarbon sector on both on-shore and off-shore auditing and consulting companies for Ecopetrol S.A. (largest energy sector company in Colombia).<br><br>Cesar has also worked at Pacific Rubiales Energy (now Frontera Energy) and Sociedad Portuaria de Puerto Bahía (Cartagena).",
    zakiDescription: "Zaki is a young entrepreneur, as a CEO in start up digital company in Indonesia, General Manager of International Company in UK and Singapore, and running several companies in Indonesia. With experience of managing start up businesses, mostly in IT projects.",
    jamesDescription: "He has more than 10 years of consulting experience (including his experience in Accenture and Deloitte) in the financial services, telecommunications and technology industries.<br><br>He is a graduate of the Commerce program (Honorable Mention) at University College Dublin and holds professional qualifications in management accounting, program execution and operations.",
    // Footer
    footerTitle: "Learn about the latest trends in ODR technology",
    footerDescription:
      "Visit our blog to be updated on all the information that your company needs.",
    footerFormTitle:
      "Find answers to your questions and get a quote for your ODR system",
    footerFormName: "Name",
    footerFormEmail: "E-mail",
    footerFormMessage: "Message",
    footerFormPrivacyPolicy: "I have read and I accept the privacy policy",
    footerFormContactUs: "Contact Us",
    accessBlog: "Access Blog",
    ourTeam: "Our Team",
    successfullCases: "Successful Cases",
    footerContact: "Contact",
}