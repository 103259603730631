<template>
  <transition name="fade" appear>
    <div class="w-full min-h-screen font-sans text-white max-w-full">
      <Jumbotron @login="toRegisterPage()" />
      <Services />
      <WhoCanAccess />
      <SuccessStory />
      <WhyUse />
      <About />
      <Profile />
      <KeyScope />
      <AtRedek />
      <Footer />
      <ScrollToUp />
      <!-- Modal -->
      <div
        v-if="showModal"
        class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex py-96"
      >
        <div class="relative w-auto my-6 mx-auto max-w-6xl">
          <!--content-->
          <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
          >
            <Login @close="toLoginPage()" />
          </div>
        </div>
      </div>
      <div
        v-if="showModal"
        class="opacity-25 fixed inset-0 z-40 bg-black"
      ></div>
      <!-- End Modal -->
    </div>
  </transition>
</template>

<script>
import "remixicon/fonts/remixicon.css";
import { useI18n } from "vue-i18n";

import Jumbotron from "./components/Jumbotron.vue";
import Services from "./components/Services.vue";
import WhoCanAccess from "./components/WhoCanAccess.vue";
import SuccessStory from "./components/SuccessStory.vue";
import WhyUse from "./components/WhyUse.vue";
import About from "./components/About.vue";
import Profile from "./components/Profile.vue";
import KeyScope from "./components/KeyScope.vue";
import AtRedek from "./components/AtRedek.vue";
import Footer from "./components/Footer.vue";
import Login from "./components/Login.vue";
import ScrollToUp from "./components/ScrollToUp.vue";

export default {
  name: "RedekLandingPage",
  data: () => ({
    showModal: false,
    odrBaseUrl: "https://stg-odr.redek.co/",
  }),
  methods: {
    toggleModal: function () {
      this.showModal = !this.showModal;
    },
    toRegisterPage() {
      window.location.href = this.odrBaseUrl + "register";
    },
    toLoginPage() {
      window.location.href = this.odrBaseUrl + "login";
    },
  },
  setup() {
    const { t, n } = useI18n();
    return {
      t,
      n,
    };
  },
  components: {
    Jumbotron,
    Services,
    WhoCanAccess,
    SuccessStory,
    WhyUse,
    About,
    Profile,
    KeyScope,
    AtRedek,
    Footer,
    Login,
    ScrollToUp,
  },
};
</script>

<style>
@import "../public/assets/css/custom.css";
</style>
