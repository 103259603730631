<template>
  <img
    :src="`/assets/blob-right.png`"
    alt="blobright"
    class="absolute md:w-160 pointer-events-none opacity-20"
    :class="
      positionClass !== ''
        ? positionClass
        : 'md:top-10 md:right-0 right-0 top-32'
    "
  />
</template>
<script>
export default {
  name: "BlobRight",
  props: {
    positionClass: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
