<template>
  <div id="success-story" class="relative h-auto">
    <BlobLeft />
    <BlobRight />
    <p
      class="text-primary text-center pt-20 font-[1100] text-5xl font-extrabold"
    >
      {{ $t("successStoryTitle") }}
    </p>
    <div class="py-12 px-6 mx-auto max-w-5xl">
      <div
        class="flex flex-col md:flex-row w-full h-full max-h-full relative p-8 py-10 space-y-4 bg-bluemarine text-black z-10 rounded-2xl duration-500 ease-in-out cursor-pointer hover:shadow-2xl"
      >
        <div class="md:w-2/6 w-full self-center align-middle">
          <div class="py-4 justify-center">
            <img
              src="/assets/general-office.png"
              :alt="$t('successStoryTitle')"
              class="lg:w-44 w-32 mx-auto"
            />
          </div>
          <div class="w-full text-center text-primary font-black">
            <h2 class="text-base">{{ $t("successStorySubTitle") }}</h2>
            <h2 class="text-base">{{ $t("successStorySubTitleSub") }}</h2>
          </div>
        </div>
        <div class="w-2/6 self-center mx-auto hidden md:block">
          <img
            :src="`/assets/finger-right.png`"
            alt="finger"
            class="z-10 w-20 mx-auto"
          />
        </div>
        <div class="md:w-2/6 w-full">
          <div class="w-full text-center">
            <p class="text-xs font-bold leading-relaxed text-justify">
              {{ $t("successStoryDescription1") }}
            </p>
            <p class="text-xs font-bold leading-relaxed pt-2 text-justify">
              {{ $t("successStoryDescription2") }}
            </p>
            <div class="pt-8">
              <button
                type="button"
                class="bg-bluebutton text-sm text-white py-1 px-4 rounded-lg font-extrabold hover:text-primary hover:bg-white duration-500"
              >
                {{ $t("viewAllCases") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BlobLeft from "./BlobLeft.vue";
import BlobRight from "./BlobRight.vue";
export default {
  name: "SuccessStoryComponent",
  components: {
    BlobLeft,
    BlobRight,
  },
};
</script>
