<template>
  <div
    id="jumbotron"
    class="relative bg-primary h-auto md:pb-48 overflow-x-hidden overflow-y-hidden"
  >
    <div class="antialiased" id="app">
      <nav
        class="container mx-auto flex items-center justify-between flex-wrap bg-teal p-6"
      >
        <div class="flex items-center flex-no-shrink text-white mr-6">
          <Logo class="w-24" />
        </div>
        <div class="block sm:hidden">
          <button
            @click="toggle"
            class="flex items-center px-3 py-2 border rounded text-teal-lighter border-teal-light hover:text-white hover:border-white"
          >
            <svg
              class="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <transition name="fade" appear>
          <div
            :class="open ? 'block' : 'hidden'"
            class="w-full flex-grow sm:flex sm:items-center sm:w-auto"
          >
            <div class="text-sm sm:flex-grow md:space-x-10 text-center">
              <span
                v-for="(menu, index) in menus"
                :key="index"
                class="text-white hover:text-gray-500 duration-500 text-sm cursor-pointer z-10 text-right md:text-center block sm:inline-block"
                :class="index === 0 ? 'mt-0' : 'mt-4'"
                @click="toId(menu.link)"
              >
                {{ $t(`${menu.key}`) }}
              </span>
            </div>
            <div class="mt-4 md:mt-0 flex items-end justify-end">
              <button
                v-if="showLogin"
                href="#"
                class="bg-white text-primary py-1 px-6 rounded-md mr-4 cursor-pointer hover:bg-bluebutton hover:text-white duration-500"
                @click="login()"
              >
                {{ $t("signIn") }}
              </button>
              <button
                href="#"
                class="bg-bluebutton text-white py-2 px-6 rounded-md cursor-pointer hover:bg-white hover:text-primary duration-500"
                @click="login()"
              >
                {{ $t("signUp") }}
              </button>
              <select
                class="bg-white text-primary py-2 px-6 rounded-md ml-4 cursor-pointer duration-500"
                @change="changeLanguage($event)"
              >
                <option value="en" :selected="$route.query.lang !== 'es'">
                  {{ $t("english") }}
                </option>
                <option value="es" :selected="$route.query.lang === 'es'">
                  {{ $t("spanish") }}
                </option>
              </select>
            </div>
          </div>
        </transition>
      </nav>
    </div>
    <BlobRight class="hidden md:block" />
    <BlobLeft />
    <div class="px-6 pt-20 mx-auto max-w-6xl">
      <div class="items-center text-white">
        <div class="md:pr-8 text-center md:pb-12 md:text-left">
          <h1
            class="relative z-10 text-center sm:text-2xl text-4xl font-bold md:text-3xl"
          >
            {{ $t("jumbotronText") }}
          </h1>
          <p class="pt-8 md:text-xs text-base text-center leading-relaxed">
            {{ $t("jumbotronSubText") }}
          </p>
        </div>
      </div>
    </div>
    <div class="md:relative md:max-w-7xl md:ml-36 z-10">
      <img
        :src="`/assets/jumbotron-screenshot.png`"
        alt="screenshot"
        class="z-10 2xl:w-256 lg:w-200 w-128 md:absolute"
      />
    </div>
    <div class="relative max-w-7xl mx-auto block pt-44">
      <MainIllustration
        class="z-10 xl:w-96 w-60 absolute xl:top-0 bottom-0 right-0 xl:right-32"
      />
    </div>
  </div>
</template>
<script>
import Logo from "./Logo.vue";
import BlobLeft from "./BlobLeft.vue";
import BlobRight from "./BlobRight.vue";
import MainIllustration from "/public/assets/illustration-main.svg";

export default {
  name: "JumbotronComponent",
  inject: ["toId"],
  components: {
    Logo,
    BlobLeft,
    BlobRight,
    MainIllustration,
  },
  data: () => ({
    showLogin: false,
    open: false,
    menus: [
      { key: "home", link: "jumbotron" },
      { key: "services", link: "services" },
      { key: "aboutUs", link: "about" },
      // { key: "blog", link: "#" },
      { key: "ourTeam", link: "profile" },
      { key: "contactUs", link: "footer" },
    ],
  }),
  methods: {
    login() {
      this.$emit("login");
    },
    changeLanguage(event) {
      this.$router.push({
        name: "index",
        query: { lang: event.target.value },
      });
    },
    toggle() {
      this.open = !this.open;
      this.toggleButton = !this.toggleButton;
    },
  },
};
</script>
