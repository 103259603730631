// initial state
const state = () => ({
    headerMode: 'full',
    headerData: {
      userFullName: '',
      instituteId: '',
      instituteName: '',
      balance: '0',
      points: '0',
      isAuthenticated: false,
      isTrollyShowed: false,
      totalCart: 0,
    },
  })
  
  // getters
  const getters = {
    headerMode(state) {
      return state.headerMode
    },
    headerData(state) {
      return state.headerData
    },
  }
  
  // actions
  const actions = {}
  
  // mutations
  const mutations = {
    setHeaderMode(state, val) {
      state.headerMode = val
    },
    setHeaderData(state, val) {
      state.headerData = val
    },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  }
  