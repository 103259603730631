<template>
  <div
    class="relative h-auto text-primary pt-10 md:pb-52 pb-10 overflow-x-hidden"
  >
    <BlobLeft class="md:block hidden" />
    <BlobRight positionClass="-top-80 -right-32" />
    <div
      class="cursor-pointer w-72 hidden md:block"
      v-for="(scope, index) in keyScopes"
      :class="scope.position"
      :key="index"
      @click="
        {
          scope.isShowDescription = true;
        }
      "
      @mouseover="
        {
          scope.isShowDescription = true;
        }
      "
      @mouseleave="
        {
          scope.isShowDescription = false;
        }
      "
    >
      <p class="text-xl font-black">{{ $t(`${scope.titleKey}`) }}</p>
      <div class="relative text-center">
        <div
          v-if="scope.isShowDescription"
          class="py-6 px-10 absolute z-40"
          :class="
            scope.isShowDescription
              ? 'bg-gray-300 bg-opacity-90 text-black'
              : ''
          "
        >
          <span v-html="$t(`${scope.descriptionKey}`)"></span>
        </div>
      </div>
    </div>
    <div class="text-center font-[1100] text-5xl">
      <p class="pt-2 md:pt-10 xl:pt-20">
        {{ $t("keyScopeTitle") }}
      </p>
    </div>
    <div class="text-center text-sm md:px-96 px-10">
      <p class="py-4 md:pt-10">
        {{ $t("keyScopeSubTitle") }}
      </p>
    </div>
    <div class="flex w-full mx-auto">
      <img src="/assets/screenshot.png" class="mx-auto w-160" />
    </div>
    <div
      class="cursor-pointer md:hidden block"
      :class="{ 'pt-10': index === 0, 'text-right': index % 2 === 0 }"
      v-for="(scope, index) in keyScopes"
      :key="index"
    >
      <p class="text-xl font-black px-10 pt-5 pb-2">
        {{ $t(`${scope.titleKey}`) }}
      </p>
      <div class="relative">
        <div class="pb-6 px-10 z-40 text-sm">
          <span v-html="$t(`${scope.descriptionKey}`)"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BlobLeft from "./BlobLeft.vue";
import BlobRight from "./BlobRight.vue";
import Logo from "./Logo.vue";

export default {
  name: "KeyScopeComponent",
  components: {
    BlobLeft,
    BlobRight,
    Logo,
  },
  data: () => ({
    keyScopes: [
      {
        titleKey: "keyScopeTitle1",
        descriptionKey: "keyScopeDescription1",
        position: "absolute top-10 left-32",
        isShowDescription: false,
      },
      {
        titleKey: "keyScopeTitle2",
        descriptionKey: "keyScopeDescription2",
        position: "absolute top-72 left-24",
        isShowDescription: false,
      },
      {
        titleKey: "keyScopeTitle3",
        descriptionKey: "keyScopeDescription3",
        position: "absolute bottom-56 left-56",
        isShowDescription: false,
      },
      {
        titleKey: "keyScopeTitle4",
        descriptionKey: "keyScopeDescription4",
        position: "absolute top-10 right-32",
        isShowDescription: false,
      },
      {
        titleKey: "keyScopeTitle5",
        descriptionKey: "keyScopeDescription5",
        position: "absolute top-72 right-24",
        isShowDescription: false,
      },
      {
        titleKey: "keyScopeTitle6",
        descriptionKey: "keyScopeDescription6",
        position: "absolute bottom-56 right-36",
        isShowDescription: false,
      },
      {
        titleKey: "keyScopeTitle7",
        descriptionKey: "keyScopeDescription7",
        position: "absolute inset-x-0 mx-auto -mt-1 bottom-32",
        isShowDescription: false,
      },
    ],
  }),
};
</script>
