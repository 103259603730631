<template>
  <div id="about" class="relative h-auto max-w-7xl mx-auto md:py-56 py-2">
    <img
      src="/assets/laptop-screenshot.png"
      alt="laptop screenshot"
      class="md:absolute md:-top-0 md:-left-32 w-200 block"
    />
    <div class="flex flex-col md:flex-row px-10 md:px-2 h-full text-primary items-center">
      <div class="md:w-3/6 w-full"></div>
      <div
        v-if="isShowAbout"
        class="md:w-3/6 w-full md:bg-white bg-transparent z-10 md:px-10 px-0 transition-all ease-in-out duration-500"
      >
        <p
          class="text-lg font-extrabold cursor-pointer hover:text-gray-500"
          @click="
            {
              isShowAbout = false;
            }
          "
        >
          <p><i class="text-2xl ri-arrow-left-line"></i></p>
          {{ $t('aboutRedek') }}
        </p>
        <div class="text-black">
          <p
            v-for="key in dataAbout.paragraphKey"
            class="pt-8 pr-8 text-justify"
          >
            {{ $t(`${key}`) }}
          </p>
        </div>
      </div>
      <div v-else class="md:w-3/6 w-full md:bg-white bg-transparent z-10 py-10">
        <h1 class="text-5xl font-extrabold">{{ $t('aboutTitle') }}</h1>
        <p class="py-10 text-black">
          {{ $t('aboutSubTitle') }}
        </p>
        <p
          class="text-lg font-extrabold cursor-pointer hover:text-gray-500"
          @click="
            {
              isShowAbout = true;
            }
          "
        >
          {{ $t('aboutRedek') }}
          <p><i class="text-2xl ri-arrow-right-line"></i></p>
        </p>
      </div>
      <div class="md:absolute md:bottom-10 md:right-10 z-10 py-5 md:py-0">
        <Logo colorClass="text-primary" color="blue" />
      </div>
    </div>
  </div>
</template>
<script>
import BlobLeft from "./BlobLeft.vue";
import BlobRight from "./BlobRight.vue";
import Logo from "./Logo.vue";

export default {
  name: "AboutComponent",
  components: {
    BlobLeft,
    BlobRight,
    Logo,
  },
  data: () => ({
    title: "About",
    isShowAbout: false,
    dataAbout: {
      paragraphKey: [
        "aboutParagraph1",
        "aboutParagraph2",
        "aboutParagraph3",
        "aboutParagraph4",
      ],
    },
  }),
};
</script>
