<template>
  <div class="relative h-auto bg-primary overflow-y-hidden">
    <div class="md:float-right lg:pt-10 lg:pr-32 p-10">
      <Logo class="mx-auto md:mx-0" />
    </div>
    <p
      class="text-white text-center md:text-left lg:pl-44 md:pt-14 md:pl-14 font-[1100] text-3xl"
    >
      {{ $t("whoCanAccessTitle") }}
    </p>
    <div class="py-12 px-6 mx-auto max-w-7xl">
      <div class="flex flex-col md:flex-row gap-5 w-full md:divide-x-2">
        <div
          v-for="who in whos"
          class="flex flex-col md:w-1/3 w-full h-auto max-h-full relative p-8 space-y-4 bg-primary z-10 transition-shadow duration-500 ease-out cursor-pointer hover:shadow-xl"
        >
          <div class="flex flex-wrap items-center space-x-3">
            <div class="mx-auto">
              <img :src="who.icon" :alt="who.title" class="lg:w-44 w-32" />
            </div>
            <div class="w-full text-center pt-8 font-extrabold">
              <h2 class="lg:text-lg text-base">
                {{ $t(`${who.titleKey}`) }}
              </h2>
            </div>
          </div>
          <div class="w-full text-center pt-4">
            <p class="lg:text-base text-xs leading-relaxed text-justify">
              {{ $t(`${who.descriptionKey}`) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Logo from "./Logo.vue";

export default {
  name: "WhoCanAccessComponent",
  components: {
    Logo,
  },
  data: () => ({
    whos: [
      {
        icon: "/assets/who/public.png",
        titleKey: "titleWho1",
        descriptionKey: "descriptionWho1",
        link: "#",
      },
      {
        icon: "/assets/who/private.png",
        titleKey: "titleWho2",
        descriptionKey: "descriptionWho2",
        color: "bg-blue",
      },
      {
        icon: "/assets/who/arbitration.png",
        titleKey: "titleWho3",
        descriptionKey: "descriptionWho3",
        color: "bg-pink",
      },
    ],
  }),
};
</script>
