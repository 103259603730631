import Index from '@/App.vue'
import NotFound from '@/views/NotFound.vue'

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
    {
      path: '/',
      name: 'index',
      meta: { title: 'Redek Landing Page', layout: 'default' },
      component: Index,
    },
    { path: '/404', component: NotFound },
    { path: '/:path(.*)', component: NotFound },
]