import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/vuex'
import { routes } from './routes.js'
import toId from './plugins/toId.js'
import i18n from './plugins/i18n.js'

import './main.css'
import { LANGUAGES } from './constants'


const app = createApp(App)
const router = createRouter({
    history: createWebHistory(),
    routes,
  })

  router.beforeEach((to, from, next) => {
    // use the language from the routing param or default language
    let language = to.query.lang
    if (!LANGUAGES.includes(language)) {
      language = LANGUAGES[0]
    }
    const { locale } = i18n.global
    locale.value = language
  
    next()
  })

app.use(store)
app.use(i18n)
app.use(router)
app.provide('toId', toId)
app.mount('#app')
