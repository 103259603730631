<template>
  <div id="footer" class="relative h-auto bg-primary">
    <div class="py-12 px-6 mx-auto xl:max-w-7xl max-w-6xl">
      <div class="flex flex-col md:flex-row w-full">
        <div
          class="flex md:w-2/6 w-full h-auto max-h-full relative py-8 md:py-24 md:px-10 space-y-4 cursor-pointer"
        >
          <div class="w-full">
            <div class="items-center self-center">
              <div class="mx-auto">
                <img
                  src="/assets/workspace.png"
                  alt="workspace"
                  class="lg:w-44 w-32 mx-auto"
                />
              </div>
              <div class="text-center pt-2">
                <h2 class="lg:text-xl text-lg font-extrabold">
                  {{ $t("footerTitle") }}
                </h2>
              </div>
            </div>
            <div class="text-center pt-6 px-10">
              <p class="lg:text-sm text-xs leading-relaxed">
                {{ $t("footerDescription") }}
              </p>
              <div class="pt-4">
                <button
                  href="#"
                  class="bg-white text-primary py-1 px-4 font-bold rounded-md text-sm mr-4 hover:bg-bluebutton hover:text-white duration-500"
                >
                  {{ $t("accessBlog") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="md:w-1/6 w-full"></div>
        <div
          class="flex md:w-3/6 w-full h-auto max-h-full relative py-8 md:pl-8 space-y-4 cursor-pointer"
        >
          <div class="md:w-5/6 w-full bg-bluemarine text-primary rounded-xl">
            <div class="items-center self-center">
              <div class="text-center pt-8 px-8 font-extrabold">
                <h2 class="lg:text-xl text-lg">
                  {{ $t("footerFormTitle") }}
                </h2>
              </div>
            </div>
            <div class="md:text-center pt-10 px-10">
              <form class="w-full max-w-sm">
                <div class="md:flex md:items-center mb-6">
                  <div class="md:w-1/3 w-full">
                    <label
                      class="block font-extrabold md:text-left mb-1 md:mb-0 md:pl-6"
                      for="inline-full-name"
                    >
                      {{ $t("footerFormName") }}
                    </label>
                  </div>
                  <div class="md:w-2/3 w-full">
                    <input
                      class="bg-white appearance-none border border-blue-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-bluebutton"
                      id="inline-name"
                      type="text"
                      :placeholder="$t('footerFormName')"
                    />
                  </div>
                </div>
                <div class="md:flex md:items-center mb-6">
                  <div class="md:w-1/3">
                    <label
                      class="block font-extrabold md:text-left mb-1 md:mb-0 md:pl-6"
                      for="inline-email"
                    >
                      {{ $t("footerFormEmail") }}
                    </label>
                  </div>
                  <div class="md:w-2/3">
                    <input
                      class="bg-white appearance-none border border-blue-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-bluebutton"
                      id="inline-email"
                      type="email"
                      :placeholder="$t('footerFormEmail')"
                    />
                  </div>
                </div>
                <div class="md:flex md:items-center mb-2">
                  <div class="md:w-1/3">
                    <label
                      class="block font-extrabold md:text-left mb-1 md:mb-0 md:pl-6"
                      for="inline-message"
                    >
                      {{ $t("footerFormMessage") }}
                    </label>
                  </div>
                  <div class="md:w-2/3">
                    <textarea
                      class="bg-white appearance-none border border-blue-100 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-bluebutton resize-none"
                      rows="5"
                      id="inline-message"
                      type="email"
                      :placeholder="$t('footerFormMessage')"
                    />
                  </div>
                </div>
                <div class="md:flex md:items-center">
                  <div class="md:w-1/3"></div>
                  <label class="md:w-2/3 block font-bold">
                    <input class="mr-1 leading-tight" type="checkbox" />
                    <span class="text-xs">
                      {{ $t("footerFormPrivacyPolicy") }}
                    </span>
                  </label>
                </div>
                <div class="py-6 text-center">
                  <button
                    href="#"
                    class="bg-bluebutton text-white py-2 px-6 font-bold rounded-md text-sm mr-4 hover:bg-white hover:text-primary duration-500"
                  >
                    {{ $t("footerFormContactUs") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap w-full bg-bluemarine text-primary">
      <div class="md:w-1/6 w-3/6 pt-6 pb-4 md:pl-20 pl-10">
        <ul class="text-sm">
          <li v-for="menu in menus">
            <span
              @click="toId(menu.link)"
              class="hover:text-gray-500 font-bold cursor-pointer"
            >
              {{ $t(`${menu.key}`) }}
            </span>
          </li>
        </ul>
      </div>
      <div class="md:w-1/6 w-3/6 pt-6 pb-4 md:pl-20 pl-2">
        <p class="text-sm font-black mb-2">{{ $t("footerContact") }}</p>
        <ul class="text-sm">
          <li>
            <a
              :href="`mailto:${contact.email}`"
              class="hover:text-gray-500 font-bold cursor-pointer"
            >
              {{ contact.email }}
            </a>
          </li>
          <li>
            <a
              :href="`tel:${contact.phone}`"
              class="hover:text-gray-500 font-bold cursor-pointer"
            >
              {{ `Tel: ${contact.phone}` }}
            </a>
          </li>
        </ul>
      </div>
      <div class="md:w-4/6 w-full">
        <div class="md:float-right lg:pt-10 lg:pr-32 md:p-10 pt-10">
          <Logo colorClass="text-black" color="black" class="mx-auto md:mx-0" />
        </div>
      </div>
      <footer class="w-full text-center pb-6">
        <p class="lg:text-sm text-xs text-black">
          Copyright &copy; 2021 REDEK | REDEK Red De Soluciones
        </p>
      </footer>
    </div>
  </div>
</template>
<script>
import Logo from "./Logo.vue";

export default {
  name: "FooterComponent",
  inject: ["toId"],
  components: {
    Logo,
  },
  data: () => ({
    menus: [
      { key: "ourTeam", link: "profile" },
      { key: "services", link: "services" },
      { key: "blog", link: "#" },
      { key: "successfullCases", link: "success-story" },
    ],
    contact: {
      email: "info@redek.co",
      phone: "+57 317 656 6432",
    },
  }),
};
</script>
