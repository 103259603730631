<template>
  <div class="relative h-auto bg-primary">
    <BlobLeft class="hidden md:block" />
    <BlobRight class="hidden md:block" />
    <div class="text-white text-center font-[1100] text-3xl">
      <p class="pt-20 md:pt-10">
        {{ $t("whyUseTitle") }}
      </p>
      <p>
        {{ $t("whyUseSubTitle") }}
      </p>
    </div>
    <div class="py-12 px-6 mx-auto max-w-6xl">
      <div class="flex flex-col gap-0 md:gap-5 md:flex-row">
        <div
          v-for="(why, whyIndex) in whys"
          class="flex flex-col w-full md:w-3/6 h-auto max-h-full relative py-0 md:py-8 px-2 md:px-10 pb-0 lg:pb-24 bg-transparent md:bg-primary z-10"
        >
          <div
            v-for="(whyPoint, pointIndex) in why"
            class="lg:py-10 py-0"
            :class="{
              'text-right md:text-left': pointIndex % 2 === 1,
              'pt-0': whyIndex === 0,
            }"
          >
            <div class="flex flex-row pt-6">
              <div class="w-full">
                <h2 class="text-lg font-black lg:text-xl md:font-extrabold">
                  {{ $t(`${whyPoint.titleKey}`) }}
                </h2>
              </div>
            </div>
            <div class="w-full pt-2">
              <p class="text-xs lg:text-sm leading-relaxed">
                {{ $t(`${whyPoint.descriptionKey}`) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BlobLeft from "./BlobLeft.vue";
import BlobRight from "./BlobRight.vue";
export default {
  name: "ServicesComponent",
  components: {
    BlobLeft,
    BlobRight,
  },
  data: () => ({
    whys: [
      [
        {
          titleKey: "whyUseTitle1",
          descriptionKey: "whyUseDescription1",
        },
        {
          titleKey: "whyUseTitle2",
          descriptionKey: "whyUseDescription2",
        },
        {
          titleKey: "whyUseTitle3",
          descriptionKey: "whyUseDescription3",
        },
        {
          titleKey: "whyUseTitle4",
          descriptionKey: "whyUseDescription4",
        },
      ],
      [
        {
          titleKey: "whyUseTitle7",
          descriptionKey: "whyUseDescription7",
        },
        {
          titleKey: "whyUseTitle5",
          descriptionKey: "whyUseDescription5",
        },
        {
          titleKey: "whyUseTitle6",
          descriptionKey: "whyUseDescription6",
        },
        {
          titleKey: "whyUseTitle8",
          descriptionKey: "whyUseDescription8",
        },
      ],
    ],
  }),
};
</script>
