<template>
  <div
    id="at-redek"
    class="relative h-auto overflow-x-hidden pb-10 md:pb-0 3xl:pb-20"
  >
    <div class="flex flex-col md:flex-row w-full h-full">
      <div class="flex flex-wrap md:w-2/6 w-full bg-primary h-full text-white">
        <div class="flex w-full text-center font-[1100] text-2xl px-12">
          <p class="py-28 xl:py-14">
            {{ $t("atRedekTitle") }}
          </p>
        </div>
        <div class="flex w-full">
          <JudgeIllustration />
        </div>
      </div>
      <div class="flex md:w-4/6 w-full flex-wrap bg-white h-full">
        <div
          v-for="(scope, index) in keyScopes"
          :key="index"
          class="flex w-full lg:px-20 xl:pt-10 pt-8"
          :class="{ '-mt-10': index > 0, 'pt-0': index === 0 }"
        >
          <div class="md:px-20 px-10 text-primary pt-10 2xl:pt-32">
            <p class="inline mr-2">
              <i class="text-2xl ri-speed-fill"></i>
            </p>
            <p
              class="inline 2xl:text-2xl xl:text-xl text-lg font-extrabold pr-0 md:pr-14 xl:pr-0 2xl:pr-56"
            >
              {{ $t(`${scope.titleKey}`) }}
            </p>
            <p
              class="text-base 2xl:text-xl xl:text-lg text-black pl-12 pt-4 text-justify"
            >
              {{ $t(`${scope.descriptionKey}`) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BlobLeft from "./BlobLeft.vue";
import BlobRight from "./BlobRight.vue";
import Logo from "./Logo.vue";
import JudgeIllustration from "/public/assets/judge-at-redek.svg";

export default {
  name: "AtRedekComponent",
  components: {
    BlobLeft,
    BlobRight,
    Logo,
    JudgeIllustration,
  },
  data: () => ({
    keyScopes: [
      {
        titleKey: "atRedekTitle1",
        descriptionKey: "atRedekDescription1",
      },
      {
        titleKey: "atRedekTitle2",
        descriptionKey: "atRedekDescription2",
      },
      {
        titleKey: "atRedekTitle3",
        descriptionKey: "atRedekDescription3",
      },
    ],
  }),
};
</script>
