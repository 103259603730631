<template>
  <div
    id="profile"
    class="h-auto bg-primary pb-2 overflow-x-hidden overflow-y-hidden"
  >
    <div class="text-white text-center font-[1100] text-3xl">
      <p class="pt-20 md:pt-10">
        {{ title }}
      </p>
    </div>
    <div class="py-10 px-6 mx-auto max-w-6xl">
      <div class="flex flex-col md:flex-row flex-wrap">
        <div
          v-for="(person, index) in persons"
          :key="index"
          class="flex h-auto text-center items-center self-center w-full md:pt-0"
          :class="[
            index > 2 ? 'md:w-3/6' : 'md:w-2/6',
            index === 0 ? 'pt-0' : 'pt-16',
          ]"
          @click="
            {
              person.isShowDescription = !person.isShowDescription;
            }
          "
        >
          <div
            :class="index > 2 ? 'md:pt-12 md:px-24' : 'pt-0'"
            class="cursor-help"
            @mouseover="
              {
                person.isShowDescription = true;
              }
            "
            @mouseleave="
              {
                person.isShowDescription = false;
              }
            "
          >
            <img
              :src="`/assets/person/${person.image}`"
              :alt="person.image"
              class="px-20"
            />
            <div class="pt-2">
              <p class="text-lg font-black">{{ person.name }}</p>
              <p class="text-xs pt-2">{{ person.role }}</p>
              <div
                v-if="person.isShowDescription"
                class="md:w-2/12 w-10/12 mx-auto md:mx-0 text-xs py-6 absolute z-40 px-10 text-justify mt-2"
                :class="
                  person.isShowDescription
                    ? 'bg-gray-300 bg-opacity-90 text-black'
                    : ''
                "
              >
                <span v-html="$t(`${person.descriptionKey}`)"></span>
                <hr width="20%" class="mx-auto mt-10 border-black" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProfileComponent",
  data: () => ({
    title: "Profiles",
    persons: [
      {
        name: "Nicolás Lozada Pimiento",
        role: "Executive Director (CEO)",
        descriptionKey: "nicolasDescription",
        image: "nicolas.png",
        isShowDescription: false,
      },
      {
        name: "Carlos Mario Lozada Pimiento",
        role: "Chief Commercial Officer (CCO)",
        descriptionKey: "carlosDescription",
        image: "carlos.png",
        isShowDescription: false,
      },
      {
        name: "César Lozada Pimiento",
        role: "Chief Administration Officer (CAO)",
        descriptionKey: "cesarDescription",
        image: "cesar.png",
        isShowDescription: false,
      },
      {
        name: "Zaki Wijaya",
        role: "Chief Technology Officer (CTO)",
        descriptionKey: "zakiDescription",
        image: "zaki.png",
        isShowDescription: false,
      },
      {
        name: "James Daly",
        role: "Chief Operations Officer (COO)",
        descriptionKey: "jamesDescription",
        image: "james.png",
        isShowDescription: false,
      },
    ],
  }),
};
</script>
