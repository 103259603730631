import { createI18n, NumberFormat } from 'vue-i18n'

import en from './../locales/en'
import es from './../locales/es'

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  globalInjection: true,
  // allowComposition: true, // you need to specify that!
  messages: {
    es,
    en,
  },
  numberFormats: {
    es: {
      currency: {
        style: 'currency',
        currency: 'COP',
        currencyDisplay: 'symbol',
      },
      currencyNoCents: {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
        maximumFractionDigits: 0,
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    en: {
      currency: {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
      },
      currencyNoCents: {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0, // set fraction digits to 0 to remove cents
        maximumFractionDigits: 0,
      },
      decimal: {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        useGrouping: false,
      },
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
})

export default i18n
