<template>
  <div v-if="showLogoText">
    <img :src="`/assets/logo.png`" :alt="alt" class="inline w-8 pb-2" />
    <span
      class="lg:text-2xl text-xl font-extrabold pl-2"
      :class="[
        customClass !== '' ? customClass : '',
        colorClass !== '' ? colorClass : 'text-white',
      ]"
    >
      {{ appName }}
    </span>
  </div>
  <div v-else class="w-32">
    <img
      v-if="color === 'white'"
      :src="`/assets/logo/logo-grey.png`"
      :alt="alt"
      class="inline"
    />
    <img
      v-if="color === 'black'"
      :src="`/assets/logo/logo-black.png`"
      :alt="alt"
      class="inline"
    />
    <img
      v-if="color === 'blue'"
      :src="`/assets/logo/logo-blue.png`"
      :alt="alt"
      class="inline"
    />
  </div>
</template>
<script>
export default {
  name: "LogoComponent",
  data: () => ({
    appName: "Redek",
    alt: "Redek Logo",
  }),
  props: {
    color: {
      type: String,
      default: "white",
    },
    showLogoText: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      required: false,
      default: "",
    },
    colorClass: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
